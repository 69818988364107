/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react"
import { hasPath } from "ramda"
import Box from "@lib/ui/core/Box"
import Link from "@components/Link"
import Typography from "@lib/ui/core/Typography"
import { linkResolver } from '@utils/link-resolver'
import { addGaEvent } from "@utils/ga-event"
import Share from "@components/Share"
import urljoin from "url-join"
// import Content from "@components/Content"

import { BodyText, Quote, Image, MediaEmbed, Ig, Related } from "@slices"

const Section = ({ children, mt = { xs: 7.5, sm: 10 }, ...rest }) => (
  <Box mt={mt} {...rest}>{children}</Box>
)

const Detail = ({ post, mediaWidth = "40.5rem", posts, offers, location, handleClickOpen }) => {
  const {
    title,
    lead,
    photo_credit,
    writing_credit,
    body,
    author,
    post_subcategory,
    date,
  } = post

  useEffect(() => {
    addGaEvent({
      ga4Event: "page_view",
      eventParams: {
        page: `/${post.post_subcategory?.name}/${post.title}-${post.prismicId}`,
        page_type: "post",
        page_id: post.prismicId,
        page_title: post.title,
      },
    })
  }, [])

  return (
    <Box>
      {hasPath(["name"], post_subcategory) && (
        <Box mb={0.5}>
          <Link to={linkResolver(post_subcategory)} data-ele-name="post-subcategory">
            {post_subcategory.name}
          </Link>
        </Box>
      )}
      {title && <Box mb={2}><Typography variant="h1">{title}</Typography></Box>}
      <Box mb={{ xs: 2, sm: 3.5 }}>{hasPath(["name"], author) && author.name + ' | '} {date}</Box>
      <Box mb={{ xs: 3.5, sm: 6 }}>
        <Share
          url={
            location && location.origin
              ? urljoin(location.origin, linkResolver(post))
              : linkResolver(post)
          } 
          align="left"
        />
      </Box>
      {lead && (
        <Box mb={{ xs: 2, sm: 3.5 }}>
          <Typography dangerouslySetInnerHTML={{ __html: lead.html }} />
        </Box>
      )}
      {Array.isArray(body)
        && body.map((details) => (
          <Box key={details.id}>
            {details.slice_type === "section" ? (
              <React.Fragment key={details.id}>
                <Section>
                  <BodyText key={details.id} content={details} posts={posts} offers={offers} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "quote" ? (
              <React.Fragment key={details.id}>
                {/* <Box display="flex" mt={10} color="actionText.selected"> */}
                <Section>
                  <Quote content={details} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "images" ? (
              <React.Fragment key={details.id}>
                {/* <Section mt={10} maxWidth="40.5rem"> */}
                <Section maxWidth={mediaWidth}>
                  <Image content={details} handleClickOpen={handleClickOpen} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "embed_media" ? (
              <React.Fragment key={details.id}>
                {/* <Box mt={10} maxWidth="40.5rem"> */}
                <Section maxWidth={mediaWidth}>
                  <MediaEmbed content={details} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "instagram_post" ? (
              <React.Fragment key={details.id}>
                {/* <Box mt={10} maxWidth="40.5rem"> */}
                <Section>
                  <Ig content={details} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "related_post" ? (
              hasPath(["primary", "related_post", "title"], details)
              && <Related content={details} key={details.id} />
            ) : ""}
          </Box>
        ))}
      {photo_credit && (
        <Box display="flex" alignItems="center">
          <Typography>圖片：{photo_credit} </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Detail;
