import React from 'react'
import { Box, Typography, SvgIcon, Grid } from '@lib/ui/core'
import useResponsive from '@hooks/use-responsive'
import { ReactComponent as Arrow } from '@assets/icons/arrow.svg'
import Link from '@components/Link'
import { linkResolver } from '@utils/link-resolver'
import { path } from 'ramda'
import Card from './CardTwo'
import { AdSlot } from 'react-dfp'

const RelatedList = ({ posts, title, totalCount = 0, fullWidth = false, loading }) => {
  const r = useResponsive()
  return (
    <Box mt={4}>
      {!fullWidth
      && (
      <Box display="flex" justifyContent="space-between" mb={2} alignItems="center">
        <Typography variant="subtitle1" component="h2">
          {title} {title && totalCount > 0 && `(${totalCount})`}
        </Typography>
        <Link to={linkResolver(path([0, "post_category"], posts))}>
          <Box display="flex" color="text.primary" alignItems="center">
            <Typography variant="h4" component="span">更多 {!title && totalCount > 0 && `(${totalCount})`}</Typography>
            <Box ml={0.5} display="flex" alignItems="center">
              <SvgIcon fontSize="inherit" component={Arrow} />
            </Box>
          </Box>
        </Link>
      </Box>
      )}
      <Grid container alignItems="stretch" spacing={r({ xs: 2, sm: 2, md: 3 })}>
      {posts.map((post, index) => {
        if(index === 4 || index === 9 || index === 14)
          return (
          <>
          <Grid item md={4} sm={4} xs={12}>
            <Box mb={2.5}><Card post={post} fullWidth loading={post ? false : loading} /></Box>
            <Box mb={2.5} mx="auto" width="300px" display={{ xs: 'block', md: 'none' }}>
              <AdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec2_300x250" sizes={[300, 250]} />
              <Typography variant="body1" align="center" style={{color: '#636363', fontSize: '0.625rem'}}>
                Sponsored
              </Typography>
            </Box>
          </Grid>
          </>
          )
        else if(index === 19)
          return (
          <>
          <Grid item md={4} sm={4} xs={12}>
            <Box mb={2.5}><Card post={post} fullWidth loading={post ? false : loading} /></Box>
            <Box mb={2.5} mx="auto" width="300px" display={{ xs: 'block', md: 'none' }}>
              <AdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec3_300x250" sizes={[300, 250]} />
              <Typography variant="body1" align="center" style={{color: '#636363', fontSize: '0.625rem'}}>
                Sponsored
              </Typography>
            </Box>
          </Grid>
          </>
          )
        else
          return(
          <>
          <Grid item md={4} sm={4} xs={12}>
            <Box mb={2.5}><Card post={post} fullWidth loading={post ? false : loading} /></Box>
          </Grid>
          </>
          )
      })}
      </Grid>
    </Box>
  )
}

export default RelatedList
