/* eslint-disable camelcase */
// import React, { useState } from "react"
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from "gatsby"
import { map } from "ramda"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import SEO from "@components/SEO"
import Divider from "@components/Divider"
import { Box, Hidden } from "@lib/ui/core"
import Share from "@components/Share"
import PostDetail from "@components/Post/Detail"
import { parsePost, parseOffer } from "@services/prismic/helpers"
import FilledCarousel from "@components/Carousel/Filled"
import TagList from "@components/Tag/List"
import urljoin from "url-join"
import { linkResolver } from "@utils/link-resolver"
import RelatedList from "@components/Post/PostList/RelatedList"
import OfferList from '@components/Offer/List'
import AppLink from '@components/AppLink'
import JsonLd from '@components/JsonLd'
import EmailPopup from '@components/EmailPopup'
import useEmailPopup from '@hooks/use-view-scroll'
import articleSchema from '@utils/json-ld/article'
import CtaBanner from '@components/CtaBanner'
import SearchInput from "@components/Search/SearchInput"
import { addGaEvent } from "@utils/ga-event"
import DeepLink from '@components/AppPopup/DeepLink'
import { navigate } from '@reach/router'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import '@assets/style/lightbox.css'
// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
// import BookmarkIcon from '@material-ui/icons/Bookmark';

const PostPage = ({ data, location, pageContext }) => {
  // const [bookMark, setBookMark] = useState(false)
  const doc = data.prismicPost
  if (!doc) return null
  const { posts, offers } = pageContext

  const post = parsePost(doc)
  const relatedPostDoc = data && data.relatedPosts && data.relatedPosts.nodes
  const relatedPosts = parsePost(relatedPostDoc)
  const hasRelatedPosts = Array.isArray(relatedPosts) && relatedPosts.length > 0

  const relatedOffersDoc = data && data.relatedOffers && data.relatedOffers.nodes
  const relatedOffers = parseOffer(relatedOffersDoc)
  const hasRelatedOffers = Array.isArray(relatedOffers) && relatedOffers.length > 0
  const ctabannerDoc = data && data.allPrismicCtaBanner && data.allPrismicCtaBanner.nodes

  const {
    featured_images: featuredImages,
    title,
    tagged,
  } = post

  const featuredImagesList = featuredImages && map((featuredImage) => {
    if (!featuredImage.image.fluid) return null
    return featuredImage.image
  }, featuredImages)

  const {
    meta_title,
    meta_keywords,
    meta_description,
    og_image,
    first_publication_date,
    last_publication_date,
  } = post

  const { siteMetadata } = data.site
  const { publisherLogo } = data

  const [anchorRef, inView] = useEmailPopup()

  const [ctaBannerData, setCtaBannerData] = useState([])

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  const handleTagClick = tag => {
    addGaEvent({
      ga4Event: "tag_click",
      eventParams: {
        item_name: tag.name,
        content_type: "post",
        item_type: "post_tag",
      },
    })
  }

  useEffect(() => {
    toggleSearchDrawer(false)
    setCtaBannerData(ctabannerDoc.sort(()=>Math.random() - 0.5))
  }, [])

  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState(false)
  const [imagesrc, setImagesrc] = useState()
  const [link, setLink] = useState()

  const handleClickOpen  = (link) => () => {
    const isDeeplink = link.includes('link.jetsobee.com')
    const isPopup = link.includes('images.prismic.io')

    if( isDeeplink ) {
      setLink(link)
      setOpen(true)
    }
    else if( isPopup ) {
      setPopup(true)
      setImagesrc(link)
    }
    else
      navigate(link)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handlePopupClose = () => {
    setPopup(false)
  }

  // const ShowBookMark = () => {
  //   if (bookMark) { setBookMark(false) } else setBookMark(true)
  // }

  return (
    <Layout type="post">
      <SEO
        title={meta_title || title}
        description={meta_description}
        keywords={meta_keywords}
        image={og_image && og_image.url}
      />
      <AppLink path={location.pathname} />
      <JsonLd>
        {articleSchema({
          url: urljoin(siteMetadata.siteUrl, linkResolver(post)),
          headline: title,
          image: Array.isArray(featuredImagesList) && featuredImagesList.map((image) => image.url),
          datePublished: first_publication_date,
          dateModified: last_publication_date,
          author: siteMetadata.title,
          publisher: {
            name: siteMetadata.title,
            logo: urljoin(siteMetadata.siteUrl, publisherLogo.childImageSharp.original.src),
          },
        })}
      </JsonLd>
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <Box mb={2.5}>
        <FilledCarousel images={featuredImagesList} />
        <Divider />
      </Box>
      <Container>
        <Box px={{ md: 20 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={{ xs: 2.5, sm: 4 }}>
            {/* <Box style={{ cursor: 'pointer' }} fontSize="1.5rem" display="flex" clone>
              {bookMark
                ? <Box onClick={() => ShowBookMark()}><BookmarkIcon color="secondary" /></Box>
                : <Box color="text.secondary"><BookmarkBorderIcon onClick={() => ShowBookMark()} /></Box>}
            </Box> */}
            {tagged && (
            <Box data-ele-section="post-tag-list">
              <TagList tags={tagged} type="post" variant="subtitle1" handleTagClick={handleTagClick} />
            </Box>
            )}
            {/* </Box> */}
          </Box>
          <Box mb={{ xs: 7.5, sm: 10 }} data-ele-section="post-detail">
            <PostDetail post={post} posts={posts} offers={offers} location={location} handleClickOpen={handleClickOpen} />
          </Box>
          <Box mb={{ xs: 7.5, sm: 10 }}>
            <Share
              url={
                location && location.origin
                  ? urljoin(location.origin, linkResolver(post))
                  : linkResolver(post)
              }
            />
          </Box>
        </Box>
      </Container>
      { ctaBannerData.length > 0 && (
        <CtaBanner ctabanner={ctaBannerData[0].data} eventCategory="post_detail_cta_banner" eventLabel={linkResolver(post)} />
      )}
      <Box bgcolor={{ sm: "grey.100" }} pt={{ sm: 1 }}>
        {hasRelatedPosts && (
          <Box pb={{ xs: 7.5, sm: 10 }}>
            <Hidden smUp>
              <Box mb={1}>
                <Divider />
              </Box>
            </Hidden>
            <Container data-ele-section="post-related-post-list">
              <RelatedList posts={relatedPosts} title="相關文章" totalCount={data.relatedPosts.totalCount} category={data.relatedPosts.post_category} />
            </Container>
          </Box>
        )}
        {hasRelatedOffers && (
          <Box pb={{ xs: 7.5, sm: 10 }}>
            <Hidden smUp>
              <Box mb={2}>
                <Divider />
              </Box>
            </Hidden>
            <Container data-ele-section="post-related-offer-list" ref={anchorRef}>
              {/* <OfferList title="為您推薦" offers={hasRelatedOffers ? relatedOffers : latestOffers} /> */}
              <OfferList title="為您推薦" offers={relatedOffers} />
            </Container>
            <EmailPopup dataEleSection="post-subscription-popup" inView={inView} />
          </Box>
        )}
      </Box>
      <DeepLink open={open} url={link} onClose={handleClose} />
      {popup && (
        <Lightbox
        mainSrc={imagesrc}
          onCloseRequest={() => handlePopupClose()}
        />)}
    </Layout>
  )
}

export default PostPage

export const query = graphql`
  query PostById($id: ID!, $categoryId: ID, $offerSubcategoryId: ID, $today: Date) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    publisherLogo: file(relativePath: { eq: "publisher-logo.png" }) {
      childImageSharp {
        original {
          src
        }
      }
    }
    allPrismicCtaBanner(
      sort: {
        fields: [
          last_publication_date
        ]
        order: [
          DESC,
          DESC
        ]
      },
      filter: {
        data: {
          from: {
            lte: $today
          },
          to: {
            gte: $today
          },
          page: {
            eq: "post_detail"
          }
        }
      }
    ) {
      totalCount
      nodes {
        data {
          image {
            url
          }
          description {
            text
          }
          button_text
          redirect_url {
            link_type
            target
            url
            document {
              ... on PrismicOffer {
                id
                uid
                type
                url
                data {
                  offer_category {
                    uid
                  }
                  offer_subcategory {
                    uid
                  }
                }
              }
              ... on PrismicPost {
                id
                uid
                type
                url
                data {
                  post_category {
                    uid
                  }
                  post_subcategory {
                    uid
                  }
                }
              }
            }
          }
          from(formatString: "LL", locale: "zh-tw")
          to(formatString: "LL", locale: "zh-tw")
          title
        }
      }
    }
    prismicPost(prismicId: {eq: $id }) {
      prismicId
      uid
      type
      data {
        title
        body {
          ...on PrismicPostBodySection {
            id
            primary {
              heading_url {
                url
              }
              heading {
                text
                html
                raw
              }
              content {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicPostBodyQuote {
          id
          primary {
            content {
                html
                raw
              }
            }
            slice_type
          }
          ... on PrismicPostBodyImages {
            id
            items {
              caption
              image {
                fluid(maxWidth: 648) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
              }
              link {
                type
                url
                target
                document {
                  ... on PrismicOffer {
                    id
                    uid
                    url
                  }
                  ... on PrismicPost {
                    id
                    uid
                    url
                  }
                }
              }
              allow_enlarge
            }
            slice_type
          }
          ... on PrismicPostBodyInstagramPost {
            id
            primary {
              instagram_embed {
                embed_url
                html
              }
            }
            slice_type
          }
          ... on PrismicPostBodyEmbedMedia {
            id
            primary {
              embed {
                title
                embed_url
                html
                type
              }
            }
            slice_type
          }
          ... on PrismicPostBodyRelatedPost {
            id
            slice_type
            primary {
              related_post {
                document {
                  ...on PrismicPost {
                    uid
                    prismicId
                    data {
                    post_subcategory {
                      uid
                    }
                    title
                    }
                    type
                  }
                }
              }
            }
          }
        }
        date
        author {
          document {
          ...on PrismicAuthor {
              id
              data {
                name
              }
            }
          }
        }
        featured_images {
          image {
            fluid(maxWidth: 1084) {
              ...GatsbyPrismicImageFluid_noBase64
            }
            alt
            url
          }
        }
        lead {
          html
          text
          raw
        }
        merchants {
          merchant {
            document {
              ...on PrismicMerchant {
                id
                data {
                  name
                  homepage_url {
                    url
                  }
                  representative_branch {
                    document {
                      ...on PrismicMerchantBranch {
                        id
                        data {
                          address1
                          address2
                          district {
                            id
                          }
                          name
                          neighborhood {
                            uid
                          }
                          region {
                            uid
                          }
                          telephones {
                            telephone
                            reminder
                            country_code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        meta_title
        focus_keyword
        meta_keywords
        meta_description
        og_image {
          url
        }
        post_subcategory {
          uid
          type
          document {
            ... on PrismicPostSubcategory {
              id
              data {
                name
                post_category {
                  uid
                  type
                }
              }
            }
          }
        }
        post_category {
          type
          uid
          document {
            ...on PrismicPostCategory {
            data {
            name
            }
            uid
            }
          }
        }
        tagged {
          tag {
            uid
            type
            document {
              ...on PrismicTag {
                id
                data {
                  name
                }
              }
            }
          }
        }
        photo_credit
        writing_credit
        editing_credit
        post_category {
          uid
        }
      }
      first_publication_date
      last_publication_date
    }
    relatedPosts: allPrismicPost(
      filter: {
        prismicId: {ne: $id}, 
        data: {post_category: {id: {eq: $categoryId}}, 
            date: {
            lte: $today
        }},
      }, 
      limit: 3, sort: {fields: data___date, order: DESC}) {
      nodes {
        prismicId
        uid
        type
        data {
          date
          featured_images {
            image {
              fluid(maxWidth: 600, maxHeight: 320) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              url
              alt
            }
          }
          title
          post_category {
            uid
            type
            document {
              ... on PrismicPostCategory {
                id
                data {
                  name
                }
              }
            }
          }
          post_subcategory {
            uid
            type
            document {
              ... on PrismicPostSubcategory {
                id
                data {
                  name
                  post_category {
                    uid
                    type
                  }
                }
              }
            }
          }
          lead {
            text
          }
          tagged {
            tag {
              type
              uid
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          author {
            uid
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
      }
      totalCount
    }
    relatedOffers: allPrismicOffer(
      limit: 6,
      filter: {
        prismicId: {
          ne: $id
        }
        data: {
          to: {
            gte: $today
          },
          offer_subcategory: {
            id: {
              eq: $offerSubcategoryId,
            }
          }
        }
      },
      sort: {
        fields: data___date,
        order: DESC
      }
    ){
      nodes {
        data {
          offer_category {
            document {
              ... on PrismicOfferCategory {
                data {
                  name
                }
                uid
                type
              }
            }
          }
          offer_subcategory {
            document {
              ... on PrismicOfferSubcategory {
                uid
                type
                data {
                  name
                  offer_category {
                    uid
                    type
                  }
                }
              }
            }
          }
          featured_images {
            image {
              thumbnails {
                thumbnail {
                  fluid(maxWidth: 240) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
              alt
            }
          }
          merchants {
            merchant {
              document {
                ... on PrismicMerchant {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
          banks {
            bank {
              document {
                ... on PrismicBank {
                  uid
                  prismicId
                  data {
                    name
                  }
                }
              }
            }
          }
          credit_cards {
            credit_card {
              document {
                ... on PrismicCreditCard {
                  uid
                  data {
                    name
                    bank {
                      id
                    }
                    card_image {
                      alt
                      url
                      dimensions {
                        width
                        height
                      }
                      fixed(height: 42){
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          short_description {
            text
          }
          tagged {
            tag {
              uid
              type
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          title
          to(formatString: "LL", locale: "zh-tw")
        }
        prismicId
        uid
        type
      }
    }
  }
`
